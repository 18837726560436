import React, { Component, useRef, useEffect } from 'react';

import theme from '@jetshop/ui/utils/theme';
// import * as cheerio from 'cheerio';
import styled from 'react-emotion';
// import theme from '@jetshop/ui/utils/theme';

import { Shortcodes } from '@jetshop/flight-shortcodes';
import Carousel, { generateDots } from 'my-react-carousel';

const Container = styled('div')`
  position: relative;
  max-width: 1600px;
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled('div')`
  position: absolute;
  top: 0;
`;

const SliderWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 2rem;

  ${Content} {
    position: relative;
  }

  .slick-slider div {
    text-align: center;
  }
  .slick-slider img {
    margin: auto;
    width: 100%;
  }

  .slick-dots {
    li {
      margin: 0 0.5rem;
    }
    li,
    button {
      width: 0.5rem;
      height: 0.5rem;
    }
    button {
      border: 1px solid #cccccc;
      display: inline-block;
      transition: 0.3s;

      ::before {
        display: none;
      }
    }
    .slick-active button {
      border: 1px solid #ac5050;
      background: #ac5050;
    }
  }
  ${theme('above.lg')} {
    ::before {
      position: absolute;
      background: linear-gradient(90deg, #f9f9f9 5%, rgba(0, 128, 128, 0) 100%);
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 15rem;
      bottom: 0;
      z-index: 2;
    }
    ::after {
      position: absolute;
      background: linear-gradient(
        270deg,
        #f9f9f9 5%,
        rgba(0, 128, 128, 0) 100%
      );
      top: 0;
      right: 0;
      content: '';
      display: block;
      width: 15rem;
      bottom: 0;
    }
  }
`;

const CarouselContainer = styled('div')`
  text-align: center;
  img {
    max-width: 100%;
  }
`;

const IndicatorSquare = styled('button')`
  width: 12px;
  height: 12px;
  border: ${({ isActive }) =>
    isActive ? '6px solid #ac5050' : '1px solid #CCCCCC'};
  margin: 16px 8px 0;
  transition: 0.3s;
  display: inline-block;
  background: transparent;
  box-sizing: border-box;
  outline: none;
  vertical-align: top;
`;

const CarouselComponent = ({ slides }) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    let interval = setInterval(() => carouselRef.current.next(), 5000);
    return () => clearInterval(interval);
  });

  return (
    <CarouselContainer>
      <Carousel
        centerCurrentSlide={true}
        slidesToShow={1}
        ref={carouselRef}
        render={generateDots(({ slides, dots }) => (
          <>
            {slides}
            {dots.map(({ index, isActive, onClick }) => (
              <IndicatorSquare
                key={index}
                isActive={isActive}
                onClick={onClick}
              />
            ))}
          </>
        ))}
      >
        {slides.map(slide => slide)}
      </Carousel>
    </CarouselContainer>
  );
};

const ShortCodeDivs = props =>
  props.className && props.className.indexOf('owl-carousel') > -1 ? (
    <CarouselComponent slides={props.children} />
  ) : (
    <div {...props} />
  );

export default class StartPageContentComponent extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }
  render() {
    return (
      <Container>
        <SliderWrapper>
          <Shortcodes
            content={this.props.children}
            components={{
              div: ShortCodeDivs
            }}
          />
        </SliderWrapper>
      </Container>
    );
  }
}
