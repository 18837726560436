import React, { Component } from 'react';
import CustomItem from './CustomItem';
import ProductComponent from '../Layout/ProductComponent';

export default class Items extends Component {
  generateItemElement(item, numberOfItems) {
    switch (item.item.__typename) {
      case 'Product':
        return (
          <ProductComponent
            product={item.item}
            productsPerRow={numberOfItems}
          />
        );

      case 'CustomItem':
        return <CustomItem item={item.item} numberOfItems={numberOfItems} />;

      default:
        return null;
    }
  }

  render() {
    const items = this.props.items.filter(item => item != null);
    const numberOfItems = items.length;
    return items.map(item => this.generateItemElement(item, numberOfItems));
  }
}
