import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import Image from '@jetshop/ui/Image/Image';
import { Link } from 'react-router-dom';

const Content = styled('div')`
  width: 100%;

  .puff-header {
    font-size: 14px;
    font-weight: 700;
    display: block;
    margin: 5px 0 0;
  }

  .start-header-bar {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: ${theme('colors.primary')};
  }

  p {
    margin: 0 0 10px;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
`;

const ImageWrapper = styled('div')`
  align-self: stretch;
`;

const Item = styled('div')`
  display: block;
  overflow: hidden;
  padding: 1rem;
  :hover {
    img {
      opacity: 0.7;
    }
  }
  a {
    width: 100%;
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    img {
      width: 100%;
    }
  }
`;

const imageSizes = [1 / 6, 1 / 5, 1 / 4, 1 / 3, 1 / 2];

export default class CustomItem extends Component {
  getImage(item) {
    let image = null;
    if (item.image !== null) {
      image = (
        <ImageWrapper>
          {this.props.numberOfItems > 1 ? (
            <Image
              src={item.image.url}
              alt=""
              sizes={imageSizes}
              aspect={'4:3'}
            />
          ) : (
            <img src={item.image.url} alt="" />
          )}
        </ImageWrapper>
      );
    }
    return <Fragment>{image}</Fragment>;
  }

  render() {
    const { item, numberOfItems } = this.props;
    const StyledItem = styled(Item)`
      width: ${100 / numberOfItems}%;

      ${theme('below.md')} {
        width: ${numberOfItems > 1 ? 50 : 100}%;
      }
    `;
    if (item.linkUrl && item.linkUrl !== '') {
      const path = item.linkUrl
        .split('/')
        .splice(3)
        .join('/');
      return (
        <StyledItem>
          <Link to={path}>
            {this.getImage(item)}
            <Content dangerouslySetInnerHTML={{ __html: item.content }} />
          </Link>
        </StyledItem>
      );
    } else {
      return (
        <StyledItem>
          {this.getImage(item)}
          <Content dangerouslySetInnerHTML={{ __html: item.content }} />
        </StyledItem>
      );
    }
  }
}
