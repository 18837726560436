import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

import Image from '@jetshop/ui/Image';
import { MainSectionWrapper } from './StartPage';
import { TrendLink } from '../ui/Button';

const Container = styled('div')`
  margin-bottom: 1.5rem;
`;
const DescriptionBlock = styled('div')`
  line-height: 1.25;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1.5rem;

  ${theme('above.sm')} {
    max-width: 60vw;
    padding: 2rem;
  }
  ${theme('above.md')} {
    max-width: 50vw;
    padding: 3rem;
  }
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  margin-bottom: 16px;
  ${theme('below.sm')} {
    flex-direction: column;
  }
`;

const NewProductImage = styled('div')`
  width: 50%;
  ${theme('below.sm')} {
    width: 100%;
  }
`;
const NewProductText = styled(DescriptionBlock)`
  width: 50%;
  ${theme('below.sm')} {
    width: 100%;
  }
`;
const News = styled('h3')`
  font-size: 3.75rem;
  text-align: center;
  margin-bottom: 2rem;
  ${theme('below.sm')} {
    margin-bottom: 1.5rem;
  }
`;
const SubtitleText = styled('p')`
  color: black;
  max-width: 26.25rem;
  font-size: 1.875rem;
  text-align: center;
  margin-bottom: 2rem;
  ${theme('below.sm')} {
    font-size: 1.375rem;
    margin-bottom: 1.5rem;
  }
`;

const AmbassadorImage = styled('div')`
  width: 50%;

  ${theme('below.sm')} {
    width: 100%;
  }
`;
const AmbassadorText = styled(DescriptionBlock)`
  background-color: ${({ theme }) => theme.colors.black};
  width: 50%;
  color: ${({ theme }) => theme.colors.white};
  ${theme('below.sm')} {
    width: 100%;
  }
`;
const Ambassadors = styled('h3')`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.5rem;
  ${theme('below.sm')} {
    margin-bottom: 1.5rem;
  }
`;
const LearnMore = styled('p')`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.375rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
  width: 22.5rem;

  ${theme('below.sm')} {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
    width: auto;
  }
`;
const GetInspiredButton = styled(TrendLink)`
  margin: 0.25rem;
  width: 100%;
  max-width: 18.75rem;
`;

const FirstImage = ({ src }) => (
  <NewProductImage>
    <Image src={src} aspect="4:5" cover sizes={[1, 1 / 2]} />
  </NewProductImage>
);
const FirstSquare = ({ mainText, subtitleText, buttonText, buttonUrl }) => (
  <NewProductText>
    <News>{mainText}</News>
    <SubtitleText>{subtitleText}</SubtitleText>
    <GetInspiredButton to={buttonUrl}>{buttonText}</GetInspiredButton>
  </NewProductText>
);

const SecondImage = ({ src }) => (
  <AmbassadorImage>
    <Image src={src} aspect="5:4" cover sizes={[1, 1 / 2]} />
  </AmbassadorImage>
);

const SecondSquare = ({ mainText, subtitleText, buttonText, buttonUrl }) => (
  <AmbassadorText>
    <Ambassadors>{mainText}</Ambassadors>
    <LearnMore>{subtitleText}</LearnMore>
    <GetInspiredButton to={buttonUrl}>{buttonText}</GetInspiredButton>
  </AmbassadorText>
);

const Campaign = ({ firstImage, firstSquare, secondImage, secondSquare }) => {
  return (
    <MainSectionWrapper>
      <Container>
        <Row>
          <FirstImage {...firstImage} />
          <FirstSquare {...firstSquare} />
        </Row>
        <Row>
          <SecondSquare {...secondSquare} />
          <SecondImage {...secondImage} />
        </Row>
      </Container>
    </MainSectionWrapper>
  );
};

export default Campaign;
