import React, { useMemo } from 'react';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import Items from './Items';
import StartPageContent from './StartPageContent';

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;

  &::after {
    content: ' ';
    display: block;
    flex: auto;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const StartPageRender = ({ result }) => {
  const { data } = result;

  const rows = useMemo(() => {
    if (!data || !data.startPage) return [];

    const rows = [];

    for (const key in data.startPage.items) {
      const item = data.startPage.items[key];

      if (rows[item.row] === undefined) {
        rows[item.row] = [];
      }

      rows[item.row][item.column - 1] = item;
    }

    return rows;
  }, [data]);

  if (data && data.startPage) {
    return (
      <Wrapper>
        <StartPageContent>{data.startPage.content}</StartPageContent>
        <MaxWidth>
          {rows.map((items, row) => (
            <Row
              data-testid="grid"
              className={'columns-' + items.length + ' row-' + row}
              key={row}
            >
              <Items items={items} />
            </Row>
          ))}
        </MaxWidth>
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default StartPageRender;
