import React, { Component } from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import ProductImage from '@jetshop/ui/ProductList/ProductImage';
import { default as ProductLinkComponent } from '@jetshop/ui/ProductLink';
import { Price } from '@jetshop/ui/Price';

const Item = styled('div')`
  display: flex;
  justify-content: center;
  align-self: stretch;
  overflow: hidden;
  align-self: stretch;
  width: 100%;
`;

export const StyledProductImage = styled(ProductImage)`
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 3rem;
  flex: 0;
  &:hover {
    opacity: 0.7;
  }
`;

export const ProductLink = styled(ProductLinkComponent)`
  background-color: ${theme('colors.white')};
  margin: 1rem;
  display: flex;
  align-self: stretch;
  flex-flow: column;
  width: 100%;
  text-decoration: none;
  padding: 1.3rem;
`;

export const StyledProductNameWrap = styled('div')`
  padding: 0.5rem;
  border-top: 1px solid ${theme('colors.product.wrapBorder')};
  border-bottom: 1px solid ${theme('colors.product.wrapBorder')};
  flex: 1 auto;
  > div {
    font-size: 0.8rem;
  }
`;

export const ProductName = styled('div')`
  flex-grow: 1;
  align-self: stretch;
  line-height: 1.3rem;
  font-size: 1.05rem;
  padding: 0.7rem 0 0;
  margin: 0.3rem 0 0;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: ${theme('colors.product.name')};
  > span {
    text-align: center;
  }
  :hover {
    color: ${theme('colors.product.nameHover')};
  }
`;

export const ProductSubName = styled('div')`
  flex-grow: 1;
  align-self: stretch;
  line-height: 0.9rem;
  color: ${theme('colors.product.subName')};
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0.4rem 0;
  > span {
    text-align: center;
  }
`;

export const ProductPrice = styled(Price)`
  margin-top: 0.5rem;
  line-height: 1rem;
  text-align: center;
  color: ${theme('colors.product.normalPrice')};
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  padding-top: 1rem;
  ${theme('below.md')} {
    ${Price.Old}, ${Price.New} {
      display: block;
      width: 100%;
    }
  }

  ${Price.Old}, ${Price.New} {
    display: inline;

    padding: 0 0.3rem;
  }

  ${Price.New} ~ ${Price.Old} {
    font-size: 0.8rem;
  }

  ${Price.New} {
    order: 1;
  }

  ${Price.New} {
    color: ${theme('colors.product.discountPrice')};
  }
`;

const imageSizes = [1 / 2, 1 / 3, 1 / 4, 1 / 5, 1 / 6];

const ProductContainer = styled(Item)`
  ${theme('only.xs')} {
    width: 100%;
  }

  ${theme('only.sm')} {
    width: 50%;
  }

  ${theme('only.md')} {
    width: ${100 / 3}%;
  }

  ${theme('above.lg')} {
    width: calc(${({ productsPerRow }) => 100 / productsPerRow}%);
  }
`;

export default class ProductItem extends Component {
  render() {
    const { product, productsPerRow } = this.props;

    if (product == null) {
      return null;
    }

    if (product.primaryRoute === null) {
      product.primaryRoute = {
        path: '#'
      };
    }

    return (
      <ProductContainer productsPerRow={productsPerRow}>
        <ProductLink data-testid="product" product={product}>
          <StyledProductImage
            image={product.images[0]}
            badges={product.badges}
            sizes={imageSizes}
            aspect={'4:3'}
          />
          <StyledProductNameWrap>
            <ProductName>
              <span>{product.name}</span>
            </ProductName>
            <ProductSubName
              dangerouslySetInnerHTML={{ __html: product.subName }}
            />
          </StyledProductNameWrap>
          <ProductPrice
            price={product.price}
            previousPrice={product.previousPrice}
          />
        </ProductLink>
      </ProductContainer>
    );
  }
}
